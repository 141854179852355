import { Pipe, PipeTransform } from '@angular/core';

const lang = navigator.language;
// 12:15 PM
const todayOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit", minute: "2-digit"
}
// Wed, 01/12
const weekOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short', month: "2-digit", day: "2-digit"
}
// Jan 2, 2023
const olderOptions: Intl.DateTimeFormatOptions = {
    month: "short", day: "numeric", year: "numeric"
}

// For use outside of templates
export const FormatDate = (value: Date | string | number) => {
    if (value == null || value == undefined)
        return '';
        
    if (typeof value == 'string' || typeof value == "number") {
        value = new Date(value);
    }

    const dayStart = new Date();
    dayStart.setHours(0, 0, 0, 0);

    const lastWeek = new Date(); // Up till 6 days ago from start of day.
    lastWeek.setTime(dayStart.getTime() - 6 * 24 * 60 * 60 * 1000);

    return value > dayStart // More recent than midnight.
        ? value.toLocaleTimeString(lang, todayOptions)
        : value > lastWeek  // More recent than 7 days ago.
        ? value.toLocaleDateString(lang, weekOptions)
        : value.toLocaleDateString(lang, olderOptions)
}

@Pipe({
    name: 'datetime',
    standalone: true
})
export class DateTimePipe implements PipeTransform {
    public transform(value: Date | string | number): string {
        return FormatDate(value);
    }
}
